<template>
  <div class="custom-card">
    <h3 style="opacity: 0.9" class="titulo">
      {{ $t('modulos.kanban_tipo_instrumento.titulos.titulo') }}
    </h3>
    <div class="headline">
      <div class="item">
        <span class="mr-2 mt-2 text">
          {{ $t('modulos.kanban_tipo_instrumento.cabecalho.setor') }}
        </span>
        <input-text
          style="input-situacao"
          :value="setor"
          disabled
          class="col-12 col-md-2"
        />
      </div>
      <div class="item situacao">
        <span class="mr-2 mt-2 text">
          {{ $t('modulos.kanban_tipo_instrumento.cabecalho.situacao') }}
        </span>
        <input-text
          style="input-situacao"
          :value="tipoColuna"
          disabled
          class="col-12 col-md-2"
        />
      </div>
      <CabecalhoItem
        :label="$t('modulos.kanban_tipo_instrumento.cabecalho.data')"
        :value="dataFormatada(dados.data)"
      />
      <CabecalhoItem
        :label="
          $t('modulos.kanban_tipo_instrumento.cabecalho.horas_disponiveis')
        "
        :value="dados.totalHorasDisponiveis"
      />
      <CabecalhoItem
        :label="$t('modulos.kanban_tipo_instrumento.cabecalho.horas_alocadas')"
        :value="dados.totalHorasAlocadas"
      />
    </div>

    <botao-padrao
      color="secondary"
      outlined
      @click="redireciona"
      class="mt-3 btn-voltar"
    >
      <v-icon>$arrowLeft</v-icon>
      {{ $t('geral.botoes.voltar') }}
    </botao-padrao>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CabecalhoItem from './CabecalhoItem.vue';
import helpers from '../../../../../common/utils/helpers';

export default {
  name: 'Cabecalho',
  props: { 
    dados: { type: Object, default: () => {} },
    setor: { type: String, default: ""},
    tipoColuna: { type: String, default:"" },
  },
  components: {
    CabecalhoItem,
  },
  methods: {
    redireciona() {
      this.$router.push({
        name: 'programacoes',
      });
    },

    dataFormatada(data) {
      if (!data) return '-';
      return helpers.formatarDataBr(data);
    },
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteSelecionado']),
    ...mapGetters('Autenticacao', ['usuario']),
  },
};
</script>

<style scoped lang="scss">
.headline {
  margin-bottom: 12px;
  min-width: 50rem;
  width: 50rem;
}

.titulo,
.headline {
  color: #344054;
  font-family: 'Work Sans', sans-serif !important;
}
.text {
  opacity: 0.9;
  display: flex;
  margin-right: 4px;
  margin-left: 4px;
}

.input-situacao {
  width: 398px;
}
.btn-voltar {
  text-align: right;
  margin-bottom: 4px;
}
.container-wrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
  width: 100%;
  justify-content: space-between;
}

@media (min-width: 960px) {
  .col-md-2 {
    max-width: 50% !important;
    flex: none;
  }
  .col-12 {
    max-width: 50% !important;
  }
}
@media (max-width: 960px) {
  .col-md-2 {
    max-width: 50% !important;
    flex: none;
  }
  .col-12 {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 960px) {
  .item-data,
  .item-horas {
    flex-direction: row;
  }

  .text {
    margin-right: 0;
    margin-left: 0;
  }
  .container-update {
    margin: 12px 0 12px;
  }
  .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .headline {
    min-width: auto;
    width: auto;
  }
}
</style>
