<template>
  <div>
    <Cabecalho
      :dados="cabecalho.dados"
      :setor="setor"
      :tipo-coluna="tipoColuna"
    />
    <div class="sequencia">
      <tabela-padrao-prime-vue
        ref="tabela"
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        :mostrar-seletor="false"
        :mostrar-seletor-check-box="false"
        :mostrar-acoes="false"
        @paginar="listarRegistros"
      />
    </div>
  </div>
</template>
<script>
import KanbanTipoInstrumentoService from '@common/services/cadastros/KanbanTipoInstrumentoService.js';
import Cabecalho from './components/Cabecalho.vue';
import helpers from '@/common/utils/helpers';

export default {
  components: { Cabecalho },
  data() {
    return {
      cabecalho: {
        dados: {},
      },
      setor: null,
      setorId: null,
      tipoColuna: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nomeTipoInstrumento',
            text: this.$t(
              'modulos.kanban_tipo_instrumento.tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'quantidadeInstrumentos',
            text: this.$t('modulos.kanban_tipo_instrumento.tabela.quantidade'),
            sortable: true,
            width: 50,
          },
          {
            value: 'horasAlocadas',
            text: this.$t('modulos.kanban_tipo_instrumento.tabela.horas'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'TipoInstrumento', 'Listar');

    this.tipoColuna = this.$route.query.tipoColuna;
    this.setor = this.$route.query.setor;
    this.setorId = this.$route.params.setorId;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.kanban_tipo_instrumento.titulos.listagem')
    );
  },
  methods: {
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    //paginaAtual = 1, porPagina = 10
    listarRegistros: function () {
      const parametros = {
        // page: paginaAtual,
        // amountPerPage: porPagina,
        // ...this.filtroAplicado,
        setorId: this.setorId,
        tipoColuna: this.tipoColuna,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      KanbanTipoInstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.disponibilidade.tabelaDisponibilidades;
          this.cabecalho.dados = res.data.disponibilidade;
          // this.tabela.quantidadeItens = res.data.totalItems;
          // this.tabela.quantidadePaginas = res.data.totalPages;
          // this.tabela.paginaAtual = paginaAtual;
          // this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style>
.sequencia
  > div
  > div
  > .p-datatable-wrapper
  > .p-datatable-table
  .p-resizable-column:nth-child(3),
.p-resizable-column:nth-child(4) {
  width: 80rem !important;
}

.sequencia
  > div
  > div
  > .p-datatable-wrapper
  > .p-datatable-table
  .p-datatable-tbody
  > tr
  > td {
  padding: 0.4rem !important;
}
</style>
