<template>
  <div class="item item-data">
    <span class="mr-2 mt-2 text">{{ label }}</span>
    <span class="mr-2 mt-2 text">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
  },
  data() {},
};
</script>
<style>
.item {
  height: 2.5rem;
  margin-top: 4px;
}

.item span {
  font-size: 1rem;
}
.item {
  display: flex;
  align-items: center;
  margin-right: 8px;
  width: 100%;
  justify-content: space-between;
  color: #344054;
  font-family: 'Work Sans', sans-serif !important;
   
}

.text {
  font-size: 1.2rem;
  opacity: 0.9;

  display: flex;
  margin-right: 4px;
  margin-left: 4px;
}
@media screen and (max-width: 960px) {
  .item {
    flex-direction: column;
    align-items: start;
    height: auto;
  }
}
</style>
